// import libs
import React, { FC, useEffect, useState } from "react";
import { AppContext, defaultState } from "./app-context";
import { isBrowser } from "../../utils";

// app provider
export const AppProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {

  // menus
  const [openMenu, setOpenMenu] = useState<boolean>(defaultState.openMenu);
  const [openSettings, setOpenSettings] = useState<boolean>(
    defaultState.openSettings
  );
  const [openData, setOpenData] = useState<boolean>(defaultState.openData);
  const [openSync, setOpenSync] = useState<boolean>(defaultState.openSync);

  // joyrid
  const [joyride, setJoyride] = useState(defaultState.joyride)

  const forceSWupdate = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.update()
        }
      })
    }
  }

  useEffect(() => {
    if (isBrowser) {
      const runJoyride = window.localStorage.getItem('joyride')
      if (!runJoyride) {
        setJoyride(true)
        window.localStorage.setItem('joyride', 'complete')
      }
    }
  }, [])

  return (
    <AppContext.Provider
      value={{
        // menus
        openMenu,
        setOpenMenu,
        openSettings,
        setOpenSettings,
        openData,
        setOpenData,
        openSync,
        setOpenSync,
        // joyride
        joyride,
        setJoyride
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
