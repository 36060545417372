// import libs
import { createContext, useContext, Dispatch, SetStateAction } from "react";

export interface IAppContextProps {
  // menus
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
  openSettings: boolean;
  setOpenSettings: Dispatch<SetStateAction<boolean>>;
  openData: boolean;
  setOpenData: Dispatch<SetStateAction<boolean>>;
  openSync: boolean;
  setOpenSync: Dispatch<SetStateAction<boolean>>;
  // joyride
  joyride: boolean,
  setJoyride: (enabled: boolean) => void
}

export const defaultState = {
  // menus
  openMenu: false,
  setOpenMenu: () => null,
  openSettings: false,
  setOpenSettings: () => null,
  openData: false,
  setOpenData: () => null,
  openSync: false,
  setOpenSync: () => null,
  // joyride
  joyride: false,
  setJoyride: () => null
};

// app context
export const AppContext = createContext<IAppContextProps>(defaultState);

export const useAppContext = () => useContext(AppContext);
