// import libs
import * as React from "react";
import { AppProvider } from "./src/components/app";

// import styles
import "./src/styles/global.scss";

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    {element}
  </AppProvider>
);
